import { message } from 'antd';
import { City } from '@/interfaces/city.interface';
import { IntermodalRegion } from '@/interfaces/intermodal-region.interface';
import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { getCityById, searchCity, searchRates } from '@/services/apis';
import { searchIntermodalRegionsByCity } from '@/services/apis/search-intermodal-regions-by-city';
import { SearchRatesResData } from '@/services/apis/search-rates/interface';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { SearchRateForm } from '@/pages/search-rate/interface';
import { cacheContext } from '../cache';

class SearchRateContext {
	/**
	 * 目的地 仓库城市
	 */
	private _selectedToCity: City | undefined;

	private _selectedIntermodalRegion: IntermodalRegion | undefined;

	private _searchDatas: SearchRatesResData[] | undefined;

	private _selectedSearchData: SearchRatesResData | undefined;

	// 可用次数
	private _remainingRateSearchLimit = 0;

	// 总次数
	private _rateSearchLimit = 0;

	private _isFetching = false;

	private _liveOrDrop = LiveOrDrop.Live;
	private _dropSearchDatas: SearchRatesResData[] | undefined;

	private _quoteId: string | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	get selectedToCity() {
		return this._selectedToCity;
	}

	set selectedToCity(selectedToCity: typeof this._selectedToCity) {
		runInAction(() => {
			this._selectedToCity = selectedToCity;
		});
	}

	get selectedIntermodalRegion() {
		return this._selectedIntermodalRegion;
	}

	set selectedIntermodalRegion(selectedIntermodalRegion: typeof this._selectedIntermodalRegion) {
		runInAction(() => {
			this._selectedIntermodalRegion = selectedIntermodalRegion;
		});
	}

	get dropSearchDatas() {
		return this._dropSearchDatas;
	}

	set dropSearchDatas(dropSearchDatas: typeof this._dropSearchDatas) {
		runInAction(() => {
			this._dropSearchDatas = dropSearchDatas;
		});
	}

	get searchDatas() {
		if (this.liveOrDrop === LiveOrDrop.Drop) {
			return this._dropSearchDatas;
		}
		return this._searchDatas;
	}

	set searchDatas(searchDatas: typeof this._searchDatas) {
		runInAction(() => {
			this._searchDatas = searchDatas;
		});
	}

	get selectedSearchData() {
		return this._selectedSearchData;
	}

	set selectedSearchData(selectedSearchData: typeof this._selectedSearchData) {
		runInAction(() => {
			this._selectedSearchData = selectedSearchData;
		});
	}

	get remainingRateSearchLimit() {
		return this._remainingRateSearchLimit;
	}

	set remainingRateSearchLimit(time: number) {
		runInAction(() => {
			this._remainingRateSearchLimit = time;
		});
	}

	get rateSearchLimit() {
		return this._rateSearchLimit;
	}

	set rateSearchLimit(limit: number) {
		runInAction(() => {
			this._rateSearchLimit = limit;
		});
	}

	get isFetching() {
		return this._isFetching;
	}

	set isFetching(isFetching: typeof this._isFetching) {
		runInAction(() => {
			this._isFetching = isFetching;
		});
	}

	get liveOrDrop() {
		return this._liveOrDrop;
	}

	set liveOrDrop(liveOrDrop: typeof this._liveOrDrop) {
		runInAction(() => {
			this._liveOrDrop = liveOrDrop;
			if (this._selectedSearchData) {
				if (liveOrDrop == LiveOrDrop.Drop) {
					this._selectedSearchData = this._dropSearchDatas.find(
						(data) => data.id == this._selectedSearchData.id,
					);
				}

				if (liveOrDrop == LiveOrDrop.Live) {
					this._selectedSearchData = this._searchDatas.find(
						(data) => data.id == this._selectedSearchData.id,
					);
				}
			}
		});
	}

	get quoteId() {
		return this._quoteId;
	}

	set quoteId(quoteId: typeof this._quoteId) {
		runInAction(() => {
			this._quoteId = quoteId;
		});
	}

	/**
	 * 搜索目的地仓库城市
	 * @param cityName
	 * @returns
	 */
	async searchToCity(cityName: string) {
		return await searchCity({ path: { cityName }, params: { limit: 50 } });
	}

	async getCityById(id: number) {
		return await getCityById({ path: { id } });
	}

	/**
	 * 通过 to city 搜索（推荐）港口
	 * @param cityId
	 * @returns
	 */
	async searchIntermodalRegionByCity(cityId: number) {
		return await searchIntermodalRegionsByCity({ params: { city_id: cityId } });
	}

	public async fetchSearchDatas(
		props: Pick<
			SearchRateForm,
			| 'intermodal_region_id'
			| 'to_city_id'
			| 'cntr_size'
			| 'cargo_types'
			| 'is_residential'
			| 'return_address_zipcode'
			| 'weight'
			| 'weight_unit'
		>,
		config?: {
			replaceSelectedIfNoData?: boolean;
		},
	) {
		const {
			intermodal_region_id,
			to_city_id,
			cntr_size,
			cargo_types,
			is_residential,
			return_address_zipcode,
			weight,
			weight_unit,
		} = props;
		const params = {
			intermodal_region_id,
			to_city_id,
			cntr_size,
			cargo_types,
			is_residential,
			return_address_zipcode,
			weight,
			weight_unit,
			page: 1,
			sort_by: 'total_rate' as const,
			sort_value: 'asc' as const,
		};
		try {
			this.isFetching = true;

			let res;

			try {
				res = await searchRates({
					params,
				});
			} catch (error) {
				// 请求出现错误时不会返回meta，city 和 ir 需要重新获取
				this.selectedToCity = (await getCityById({ path: { id: to_city_id } })).data.data;
				this.selectedIntermodalRegion = cacheContext.intermodalRegions.find(
					(i) => i.id == intermodal_region_id,
				);
				throw error;
			}

			let selectedToCity = res.data.data[0]?.to_city;
			let selectedIntermodalRegion = res.data.data[0]?.intermodal_region;

			// 判断是否需要保留 city 和 intermodal region
			if ((!selectedToCity || !selectedIntermodalRegion) && config?.replaceSelectedIfNoData) {
				selectedToCity = res.data.meta.to_city;
				selectedIntermodalRegion = res.data.meta.intermodalRegion;
			}

			this.searchDatas = res.data.data;
			this.dropSearchDatas = this.asDropSearchDatas(res.data.data);
			this.selectedToCity = selectedToCity;
			this.selectedIntermodalRegion = selectedIntermodalRegion;
			if (this.searchDatas?.length === 1) {
				this.selectedSearchData = this.searchDatas[0];
			}
			this.remainingRateSearchLimit = res.data.meta.remaining_limit;
			this.quoteId = res.data.meta.quote_id;
		} catch (e: any) {
			message.error(e.response.data.message ?? e.response.data.error);
		}

		this.isFetching = false;
		return this.searchDatas;
	}

	public setSelectedSearchDataById(id: number) {
		const targetSearchData = this.searchDatas?.find((d) => d.id === id);
		this.selectedSearchData = toJS(targetSearchData);
	}

	public clearState() {
		this.selectedToCity = undefined;
		this.selectedIntermodalRegion = undefined;
		this.searchDatas = undefined;
		this.selectedSearchData = undefined;
		this.liveOrDrop = LiveOrDrop.Live;
		this.quoteId = undefined;
	}

	asDropSearchDatas(searchDatas) {
		return searchDatas.map((data) => {
			return {
				...data,
				scenarios: (data.scenarios || []).map((scenario) => {
					return {
						...scenario,
						rates: [
							...scenario.rates,
							{
								name: 'Drop Fee',
								unit: 'TIME',
								qty: 1,
								rate: data.drop_fee,
								amount: data.drop_fee,
							},
						],
						total_rate: (Number(scenario.total_rate) + Number(data.drop_fee)).toFixed(2),
					};
				}),
			};
		});
	}
}

export const searchRate = new SearchRateContext();
