import axios, { AxiosResponse, HttpStatusCode } from 'axios';
import qs from 'qs';
import { unauthedNavigate } from '@/utils/unauthed-navigate';
import accessTokenManager from '../contexts/user/access-token-manager';

const defaultBaseUrl = import.meta.env.VITE_BASE_URL;
const cnBaseUrl = 'https://cnapi.drayeasy.com/api/';
export const baseURL =
	window.location.origin === 'https://cn.drayeasy.com' ? cnBaseUrl : defaultBaseUrl;

const axiosInstance = axios.create({
	baseURL,
	paramsSerializer: (params) => {
		return qs.stringify(params);
	},
});

// 错误重试
// axiosRetry(axiosInstance, {
// 	retries: 2,
// 	retryDelay: (retryCount) => Math.random() * 2000 * retryCount + 1000,
// 	retryCondition: (error) => {
// 		return error.request.status !== HttpStatus.UNAUTHORIZED;
// 	},
// });

axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		const authorization = response.headers.authorization;

		if (authorization) {
			const result = authorization.match(/^Bearer (.+)/);
			if (result) {
				accessTokenManager.setAccessToken(result[1]);
			}
		}

		if (response.status >= 400) {
			throw { response };
		}

		return response;
	},
	(error) => {
		const response: AxiosResponse = error.response;
		// 401 跳转路由并清空本地 token 和 user 信息
		if (
			response &&
			response.status === HttpStatusCode.Unauthorized
			// || response.status === HttpStatusCode.Forbidden
		) {
			unauthedNavigate();
		}

		throw error;
	},
);

export default axiosInstance;
